import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

import { Pool } from '@/services/pool/types';

import BoostedPool from './BoostedPool.vue';
import DefaultPool from './DefaultPool.vue';

/**
 * TYPES
 */
type Props = {
  pool: Pool;
  loading: boolean;
};

/**
 * PROPS
 */

export default _defineComponent({
  props: {
    pool: { type: null, required: true },
    loading: { type: Boolean, required: true, default: false }
  } as unknown as undefined,
  setup(__props: {
  pool: Pool;
  loading: boolean;
}) {



return (_ctx: any,_cache: any) => {
  const _component_BalLoadingBlock = _resolveComponent("BalLoadingBlock")!

  return (__props.loading)
    ? (_openBlock(), _createBlock(_component_BalLoadingBlock, {
        key: 0,
        class: "h-64"
      }))
    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
        (__props.pool?.onchain?.linearPools)
          ? (_openBlock(), _createBlock(BoostedPool, {
              key: 0,
              pool: __props.pool,
              loading: __props.loading
            }, null, 8, ["pool", "loading"]))
          : (_openBlock(), _createBlock(DefaultPool, {
              key: 1,
              pool: __props.pool
            }, null, 8, ["pool"]))
      ], 64))
}
}

})