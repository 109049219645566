
import { computed, defineComponent, PropType, toRef, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import APRTooltip from '@/components/tooltips/APRTooltip/APRTooltip.vue';
import useNumbers, { FNumFormats } from '@/composables/useNumbers';
import { totalAprLabel, usePool } from '@/composables/usePool';
import { APR_THRESHOLD } from '@/constants/pools';
import { Pool, PoolAPRs } from '@/services/pool/types';
import { TransactionResponse } from '@ethersproject/providers';
import MyPoolInvsetmentFiat, {
  MyPollInvestmentFiatType,
} from '@/components/pool/MyPoolInvsetmentFiat.vue';
import useStake from '@/composables/PolarisFinance/useStake';
import useWeb3 from '@/services/web3/useWeb3';
import { BigNumber } from 'ethers';
import useTransactions from '@/composables/useTransactions';
import useEthers from '@/composables/useEthers';
import useBreakpoints from '@/composables/useBreakpoints';
import { shortenLabel } from '@/lib/utils';
import BalAsset from '@/components/_global/BalAsset/BalAsset.vue';
import useTokens from '@/composables/useTokens';
import PoolCalculator from '@/services/pool/calculator/calculator.sevice';
import PoolUserStats from './PoolUserStats.vue';
import { bnum, isSameAddress } from '@/lib/utils';
import StakeModal from '@/pages/pool/StakeModal.vue';

export default defineComponent({
  data() {
    return {};
  },
  watch: {},
  components: {
    MyPoolInvsetmentFiat,
    BalAsset,
    PoolUserStats,
    StakeModal,
  },
  props: {
    pool: {
      type: Object as PropType<Pool>,
      default: null,
    },
    stakedBalance: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: true,
    },
    poolApr: {
      type: Object as PropType<PoolAPRs>,
      default: 0,
    },
    loadingApr: {
      type: Boolean,
      default: true,
    },
    xpolarPrice: {
      type: String,
      default: '',
    },
    xpolarToClaim: {
      type: String,
      default: '',
    },
    dailyAPR: {
      type: String,
      default: '',
    },
  },
  emits: ['click'],
  methods: {
    async claimXpolar(address) {
      const { withdraw } = useStake();
      const tx = await withdraw(address, BigNumber.from(0), this.getProvider());
      this.txHandler(tx);
      this.txListener(tx, {
        onTxConfirmed: () => {
          this.fetchClaims();
        },
        onTxFailed: () => {},
      });
    },
    async fetchClaims() {},
  },
  setup(props) {
    const { upToMediumBreakpoint, isMobile, isDesktop } = useBreakpoints();
    const { txListener } = useEthers();
    const isStakeModalVisible = ref(false);
    const isUnstakeModalVisible = ref(false);

    const toggleStakeModal = (value?: boolean) => {
      isStakeModalVisible.value = value ?? !isStakeModalVisible.value;
    };

    const toggleUnstakeModal = (value?: boolean) => {
      isUnstakeModalVisible.value = value ?? !isUnstakeModalVisible.value;
    };

    /**
     * COMPOSABLES
     */
    const txHandler = (tx: TransactionResponse): void => {
      addTransaction({
        id: tx.hash,
        type: 'tx',
        action: 'approve',
        summary: 'approve for staking',
      });
    };
    const { tokens, balances, balanceFor, getTokens } = useTokens();
    const { fNum2, toFiat } = useNumbers();
    const { t } = useI18n();
    const rewardFiat = computed(() => {
      return fNum2(
        Number(props.xpolarPrice) * Number(props.xpolarToClaim),
        FNumFormats.fiat
      );
    });
    const { isStableLikePool, isStablePhantomPool, isMigratablePool } = usePool(
      toRef(props, 'pool')
    );

    const unstakedTokens = computed((): string =>
      balanceFor(props.pool.address)
    );

    const totalTokens = computed((): string =>
      bnum(props.stakedBalance).plus(unstakedTokens.value).toString()
    );
    const stakedPerc = computed(() => {
      const perc = Number(props.stakedBalance) / Number(totalTokens.value);
      return Math.round(perc * 1000) / 1000;
    });

    function symbolFor(address: string) {
      if (!props.pool) return '-';
      const symbol = props.pool?.onchain?.tokens?.[address]?.symbol;
      return symbol ? symbol : shortenLabel(address);
    }

    const tokenAddresses = computed((): string[] => {
      if (isStablePhantomPool.value) {
        // We're using mainToken balances for StablePhantom pools
        // so return mainTokens here so that fiat values are correct.
        return props.pool.mainTokens || [];
      }
      return props.pool.tokensList;
    });
    // const walletFreeTokens = computed(() =>
    //   tokenAddresses.value
    //     .map((address, i) => propTokenAmounts.value[i], address)
    //     .reduce((total, value) => bnum(total).plus(value).toString())
    // );
    const xpolarToClaim = computed(() => props.xpolarToClaim);
    const stats = computed(() => {
      if (!props.pool) return [];

      return [
        {
          id: 'poolValue',
          label: t('poolValue'),
          value: fNum2(props.pool.totalLiquidity, FNumFormats.fiat),
          loading: props.loading,
        },
        {
          id: 'volumeTime',
          label: t('volumeTime', ['24h']),
          value: fNum2(props.pool.volumeSnapshot || '0', FNumFormats.fiat),
          loading: props.loading,
        },
      ];
    });

    /**
     * COMPUTED
     */
    const { addTransaction } = useTransactions();
    const { getProvider } = useWeb3();

    const aprLabel = computed((): string => {
      const poolAPRs = props.poolApr;
      if (!poolAPRs) return '0';

      return totalAprLabel(poolAPRs, props.pool.boost);
    });

    /*
     * PoolBalances
     */

    const { isWalletReady } = useWeb3();

    const poolCalculator = new PoolCalculator(
      toRef(props, 'pool'),
      tokens,
      balances,
      'exit',
      ref(false)
    );

    const propTokenAmounts = computed((): string[] => {
      const { receive } = poolCalculator.propAmountsGiven(
        bnum(totalTokens.value).toString(),
        0,
        'send'
      );

      if (isStablePhantomPool.value) {
        // Return linear pool's main token balance using the price rate.
        // mainTokenBalance = linearPoolBPT * priceRate
        return props.pool.tokensList.map((address, i) => {
          if (!props.pool?.onchain?.linearPools) return '0';

          const priceRate = props.pool.onchain.linearPools[address].priceRate;

          return bnum(receive[i]).times(priceRate).toString();
        });
      }

      return receive;
    });
    function fiatLabelFor(index: number, address: string): string {
      const fiatValue = toFiat(propTokenAmounts.value[index], address);
      return fNum2(fiatValue, FNumFormats.fiat);
    }

    const fiatValue = computed(() => {
      let fiatVal = 0;
      if (props.pool != undefined) {
        props.pool.tokens.forEach(token => {
          fiatVal += Number(toFiat(token.balance, token.address));
        });
        const lpVal = fiatVal / Number(props.pool.totalShares);
        const totalValue = lpVal * Number(props.stakedBalance);

        return fNum2(totalValue, FNumFormats.fiat);
      } else {
        return '-';
      }
    });

    const dailyAPR = props.dailyAPR;
    const dailyEarnings = Number(props.dailyAPR) * Number(props.stakedBalance);

    /**
     * METHODS
     */

    return {
      getProvider,
      txHandler,
      txListener,
      stats,
      isMobile,
      isDesktop,
      rewardFiat,
      xpolarToClaim,
      symbolFor,
      balanceFor,
      unstakedTokens,
      stakedPerc,
      dailyAPR,
      isWalletReady,
      fNum2,
      propTokenAmounts,
      FNumFormats,
      fiatLabelFor,
      totalTokens,
      fiatValue,
      dailyEarnings,
      toggleStakeModal,
      isStakeModalVisible,
      isUnstakeModalVisible,
    };
  },
  created() {},
  beforeUpdate() {},
  mounted() {},
  updated() {},
});
