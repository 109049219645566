import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, createVNode as _createVNode, withCtx as _withCtx, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-412aa014")
const _hoisted_1 = { class: "col-span-2 px-4 lg:px-0" }
const _hoisted_2 = {
  key: 1,
  class: "flex flex-col"
}
const _hoisted_3 = { class: "-mt-2 flex flex-wrap items-center" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "pool-title" }
const _hoisted_6 = { class: "text-sm" }
const _hoisted_7 = {
  key: 1,
  class: "pool-title"
}
const _hoisted_8 = { class: "ml-2" }
const _hoisted_9 = {
  key: 0,
  class: "mt-px ml-1 text-xs font-medium text-gray-400"
}
const _hoisted_10 = { class: "mt-2 flex items-center" }
const _hoisted_11 = ["innerHTML"]
const _hoisted_12 = ["innerHTML"]
const _hoisted_13 = ["innerHTML"]
_popScopeId()

import { computed, reactive } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

import BalChipNew from '@/components/chips/BalChipNew.vue';
import GauntletIcon from '@/components/images/icons/GauntletIcon.vue';
import APRTooltip from '@/components/tooltips/APRTooltip/APRTooltip.vue';
import useApp from '@/composables/useApp';
import useNumbers from '@/composables/useNumbers';
import { usePoolWarning } from '@/composables/usePoolWarning';
import useTokens from '@/composables/useTokens';
import { EXTERNAL_LINKS } from '@/constants/links';
import { POOLS } from '@/constants/pools';
import { includesAddress } from '@/lib/utils';
import { OnchainTokenData, Pool, PoolAPRs } from '@/services/pool/types';
import useWeb3 from '@/services/web3/useWeb3';

/**
 * TYPES
 */
type Props = {
  loadingPool: boolean;
  // loadingApr: boolean;
  noInitLiquidity: boolean;
  isStableLikePool: boolean;
  pool: Pool;
  // poolApr: PoolAPRs;
  titleTokens: [string, OnchainTokenData][];
  missingPrices: boolean;
  isLiquidityBootstrappingPool: boolean;
  isStablePhantomPool: boolean;
};


export default _defineComponent({
  props: {
    loadingPool: { type: Boolean, required: true, default: true },
    noInitLiquidity: { type: Boolean, required: true, default: false },
    isStableLikePool: { type: Boolean, required: true },
    pool: { type: null, required: true },
    titleTokens: { type: Array, required: true },
    missingPrices: { type: Boolean, required: true },
    isLiquidityBootstrappingPool: { type: Boolean, required: true },
    isStablePhantomPool: { type: Boolean, required: true }
  } as unknown as undefined,
  setup(__props: {
  loadingPool: boolean;
  // loadingApr: boolean;
  noInitLiquidity: boolean;
  isStableLikePool: boolean;
  pool: Pool;
  // poolApr: PoolAPRs;
  titleTokens: [string, OnchainTokenData][];
  missingPrices: boolean;
  isLiquidityBootstrappingPool: boolean;
  isStablePhantomPool: boolean;
}) {

const props = __props

console.log(props.loadingPool);
/**
 * COMPOSABLES
 */
const { appLoading } = useApp();
const route = useRoute();
const { isAffected, warnings } = usePoolWarning(route.params.id as string);
const { fNum2 } = useNumbers();
const { t } = useI18n();
const { explorerLinks: explorer } = useWeb3();
const { balancerTokenListTokens } = useTokens();

/**
 * STATE
 */
const data = reactive({
  id: route.params.id as string,
});

/**
 * COMPUTED
 */
const feesFixed = computed(() => props.pool?.owner == POOLS.ZeroAddress);

const communityManagedFees = computed(
  () => props.pool?.owner == POOLS.DelegateOwner
);
const feesManagedByGauntlet = computed(
  () =>
    communityManagedFees.value && POOLS.DynamicFees.Gauntlet.includes(data.id)
);
const swapFeeToolTip = computed(() => {
  if (feesManagedByGauntlet.value) {
    return t('feesManagedByGauntlet');
  } else if (communityManagedFees.value) {
    return t('delegateFeesTooltip');
  } else if (feesFixed.value) {
    return t('fixedFeesTooltip');
  } else {
    return t('ownerFeesTooltip');
  }
});

const poolFeeLabel = computed(() => {
  if (!props.pool || !props.pool?.onchain?.swapFee) return '';

  const feeLabel = `${fNum2(props.pool.onchain.swapFee, {
    style: 'percent',
    maximumFractionDigits: 4,
  })}`;

  if (feesFixed.value) {
    return t('fixedSwapFeeLabel', [feeLabel]);
  } else if (communityManagedFees.value) {
    return feesManagedByGauntlet.value
      ? t('dynamicSwapFeeLabel', [feeLabel])
      : t('communitySwapFeeLabel', [feeLabel]);
  }

  // Must be owner-controlled
  return t('dynamicSwapFeeLabel', [feeLabel]);
});

const hasCustomToken = computed(() => {
  const knownTokens = Object.keys(balancerTokenListTokens.value);
  return (
    !!props.pool &&
    !props.isLiquidityBootstrappingPool &&
    !props.isStablePhantomPool &&
    props.pool.tokensList.some(
      address => !includesAddress(knownTokens, address)
    )
  );
});

const poolTypeLabel = computed(() => {
  if (!props.pool) return '';
  const key = POOLS.Factories[props.pool.factory];

  return key ? t(key) : t('unknownPoolType');
});

return (_ctx: any,_cache: any) => {
  const _component_BalLoadingBlock = _resolveComponent("BalLoadingBlock")!
  const _component_BalAsset = _resolveComponent("BalAsset")!
  const _component_BalIcon = _resolveComponent("BalIcon")!
  const _component_BalLink = _resolveComponent("BalLink")!
  const _component_BalTooltip = _resolveComponent("BalTooltip")!
  const _component_BalAlert = _resolveComponent("BalAlert")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (__props.loadingPool)
      ? (_openBlock(), _createBlock(_component_BalLoadingBlock, {
          key: 0,
          class: "h-16"
        }))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (_unref(POOLS).Metadata[__props.pool?.id])
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createElementVNode("h3", _hoisted_5, _toDisplayString(_unref(POOLS).Metadata[__props.pool.id].name), 1),
                  _createElementVNode("h5", _hoisted_6, _toDisplayString(_unref(poolTypeLabel)), 1)
                ]))
              : (_openBlock(), _createElementBlock("h3", _hoisted_7, _toDisplayString(_unref(poolTypeLabel)), 1)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.titleTokens, ([address, tokenMeta], i) => {
              return (_openBlock(), _createElementBlock("div", {
                key: i,
                class: "mt-2 mr-2 flex h-10 items-center rounded-lg bg-gray-50 px-2 dark:bg-gray-850"
              }, [
                _createVNode(_component_BalAsset, { address: address }, null, 8, ["address"]),
                _createElementVNode("span", _hoisted_8, _toDisplayString(tokenMeta.symbol), 1),
                (!__props.isStableLikePool)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(_unref(fNum2)(tokenMeta.weight, {
                style: 'percent',
                maximumFractionDigits: 0,
              })), 1))
                  : _createCommentVNode("", true)
              ]))
            }), 128)),
            (__props.pool?.isNew)
              ? (_openBlock(), _createBlock(BalChipNew, {
                  key: 2,
                  class: "mt-2 mr-2"
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_BalLink, {
              href: _unref(explorer).addressLink(__props.pool?.address || ''),
              external: "",
              noStyle: "",
              class: "flex items-center"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_BalIcon, {
                  name: "arrow-up-right",
                  size: "sm",
                  class: "mt-2 ml-2 text-gray-500 transition-colors hover:text-blue-500"
                })
              ]),
              _: 1
            }, 8, ["href"])
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", {
              class: "text-secondary mr-1 text-sm",
              innerHTML: _unref(poolFeeLabel)
            }, null, 8, _hoisted_11),
            _createVNode(_component_BalTooltip, null, {
              activator: _withCtx(() => [
                (_unref(feesManagedByGauntlet))
                  ? (_openBlock(), _createBlock(_component_BalLink, {
                      key: 0,
                      href: _unref(EXTERNAL_LINKS).Gauntlet.Home,
                      external: ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(GauntletIcon)
                      ]),
                      _: 1
                    }, 8, ["href"]))
                  : (_openBlock(), _createBlock(_component_BalIcon, {
                      key: 1,
                      name: "info",
                      size: "xs",
                      class: "text-gray-400 dark:text-gray-500"
                    }))
              ]),
              default: _withCtx(() => [
                _createElementVNode("span", null, _toDisplayString(_unref(swapFeeToolTip)), 1)
              ]),
              _: 1
            })
          ])
        ])),
    (!_unref(appLoading) && !__props.loadingPool && __props.missingPrices)
      ? (_openBlock(), _createBlock(_component_BalAlert, {
          key: 2,
          type: "warning",
          title: _ctx.$t('noPriceInfo'),
          class: "mt-2",
          block: ""
        }, null, 8, ["title"]))
      : _createCommentVNode("", true),
    (!_unref(appLoading) && !__props.loadingPool && _unref(hasCustomToken))
      ? (_openBlock(), _createBlock(_component_BalAlert, {
          key: 3,
          type: "error",
          title: _ctx.$t('highRiskPool'),
          class: "mt-2",
          block: ""
        }, null, 8, ["title"]))
      : _createCommentVNode("", true),
    (!_unref(appLoading) && !__props.loadingPool && _unref(isAffected))
      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 4 }, _renderList(_unref(warnings), (warning, i) => {
          return (_openBlock(), _createBlock(_component_BalAlert, {
            key: `warning-${i}`,
            type: "error",
            class: "mt-2",
            block: ""
          }, {
            title: _withCtx(() => [
              _createElementVNode("div", {
                innerHTML: warning.title
              }, null, 8, _hoisted_12)
            ]),
            description: _withCtx(() => [
              _createElementVNode("div", {
                innerHTML: warning.description
              }, null, 8, _hoisted_13)
            ]),
            _: 2
          }, 1024))
        }), 128))
      : _createCommentVNode("", true),
    (!_unref(appLoading) && __props.noInitLiquidity)
      ? (_openBlock(), _createBlock(_component_BalAlert, {
          key: 5,
          type: "warning",
          title: _ctx.$t('noInitLiquidity'),
          description: _ctx.$t('noInitLiquidityDetail'),
          class: "mt-2",
          block: ""
        }, null, 8, ["title", "description"]))
      : _createCommentVNode("", true)
  ]))
}
}

})