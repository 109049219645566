import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "grid w-full grid-cols-3 border-b p-4 text-base font-semibold dark:border-gray-900" }
const _hoisted_2 = { class: "justify-self-end" }
const _hoisted_3 = { class: "justify-self-end" }
const _hoisted_4 = { class: "-mt-2 p-4" }

import useBreakpoints from '@/composables/useBreakpoints';
import { bnum } from '@/lib/utils';
import { Pool } from '@/services/pool/types';
import useWeb3 from '@/services/web3/useWeb3';

import AssetRow from './components/AssetRow.vue';

/**
 * TYPES
 */
type Props = {
  pool: Pool;
  loading: boolean;
};

/**
 * PROPS
 */

export default _defineComponent({
  props: {
    pool: { type: null, required: true },
    loading: { type: Boolean, required: true, default: false }
  } as unknown as undefined,
  setup(__props: {
  pool: Pool;
  loading: boolean;
}) {

const props = __props


/**
 * COMPOSABLES
 */
const { upToLargeBreakpoint } = useBreakpoints();
const { explorerLinks } = useWeb3();

/**
 * METHODS
 */
function getUnderlyingTokens(address: string) {
  const linearPools = props.pool?.onchain?.linearPools;

  if (linearPools == null) {
    return [];
  }

  const mainTokenAddress = linearPools[address].mainToken.address;

  return linearPools != null
    ? [
        linearPools[address].mainToken,
        {
          ...linearPools[address].wrappedToken,
          mainTokenAddress,
        },
      ]
    : [];
}

function getTokenShare(address: string) {
  const linearPools = props.pool?.onchain?.linearPools;

  if (linearPools == null) {
    return null;
  }

  const token = props.pool?.onchain?.tokens[address];

  return bnum(token?.balance || '0')
    .div(linearPools[address].totalSupply)
    .toString();
}

return (_ctx: any,_cache: any) => {
  const _component_BalAsset = _resolveComponent("BalAsset")!
  const _component_BalIcon = _resolveComponent("BalIcon")!
  const _component_BalLink = _resolveComponent("BalLink")!
  const _component_BalBreakdown = _resolveComponent("BalBreakdown")!
  const _component_BalCard = _resolveComponent("BalCard")!

  return (_openBlock(), _createBlock(_component_BalCard, {
    class: "overflow-x-auto whitespace-nowrap",
    square: _unref(upToLargeBreakpoint),
    noBorder: _unref(upToLargeBreakpoint),
    noPad: ""
  }, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", null, _toDisplayString(_ctx.$t('token')), 1),
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('balance')), 1),
        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('value')), 1)
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.pool.tokensList, (address) => {
          return (_openBlock(), _createElementBlock("div", {
            key: address,
            class: "py-4"
          }, [
            _createVNode(_component_BalBreakdown, {
              items: getUnderlyingTokens(address),
              class: "w-full",
              offsetClassOverrides: "mt-4 ml-3",
              initVertBarClassOverrides: "h-6 -mt-6",
              size: "lg"
            }, {
              item: _withCtx(({ item: asset }) => [
                _createVNode(AssetRow, {
                  address: asset.address,
                  mainTokenAddress: asset.mainTokenAddress,
                  balance: asset.balance,
                  priceRate: asset.priceRate,
                  share: getTokenShare(address)
                }, null, 8, ["address", "mainTokenAddress", "balance", "priceRate", "share"])
              ]),
              default: _withCtx(() => [
                _createVNode(_component_BalLink, {
                  href: _unref(explorerLinks).addressLink(address),
                  external: "",
                  noStyle: "",
                  class: "flex items-center"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_BalAsset, {
                      address: address,
                      class: "mr-2"
                    }, null, 8, ["address"]),
                    _createTextVNode(" " + _toDisplayString(__props.pool?.onchain?.tokens?.[address]?.symbol || '---') + " ", 1),
                    _createVNode(_component_BalIcon, {
                      name: "arrow-up-right",
                      size: "sm",
                      class: "ml-2 text-gray-500 transition-colors hover:text-blue-500"
                    })
                  ]),
                  _: 2
                }, 1032, ["href"])
              ]),
              _: 2
            }, 1032, ["items"])
          ]))
        }), 128))
      ])
    ]),
    _: 1
  }, 8, ["square", "noBorder"]))
}
}

})