import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { flatten } from 'lodash';
import { computed } from 'vue';
import { useRoute } from 'vue-router';

import usePoolActivitiesQuery from '@/composables/queries/usePoolActivitiesQuery';
import usePoolUserActivitiesQuery from '@/composables/queries/usePoolUserActivitiesQuery';
import { Pool } from '@/services/pool/types';

import { PoolTransactionsTab } from '../types';
import Table from './Table.vue';

/**
 * TYPES
 */
type Props = {
  pool: Pool;
  loading: boolean;
  poolActivityType: PoolTransactionsTab;
};

/**
 * PROPS
 */

export default _defineComponent({
  props: {
    pool: { type: null, required: true },
    loading: { type: Boolean, required: true, default: false },
    poolActivityType: { type: null, required: true, default: PoolTransactionsTab.ALL_ACTIVITY }
  } as unknown as undefined,
  setup(__props: {
  pool: Pool;
  loading: boolean;
  poolActivityType: PoolTransactionsTab;
}) {

const props = __props


/**
 * COMPOSABLES
 */
const route = useRoute();

/**
 * STATE
 */

const id = route.params.id as string;
/**
 * QUERIES
 */

const poolActivitiesQuery =
  props.poolActivityType === PoolTransactionsTab.ALL_ACTIVITY
    ? usePoolActivitiesQuery(id)
    : usePoolUserActivitiesQuery(id);

/**
 * COMPUTED
 */
const poolActivities = computed(() =>
  poolActivitiesQuery.data.value
    ? flatten(
        poolActivitiesQuery.data.value.pages.map(page => page.poolActivities)
      )
    : []
);

console.log(poolActivities);

const isLoadingPoolActivities = computed(
  () => poolActivitiesQuery.isLoading.value
);
const poolActivitiesHasNextPage = computed(
  () => poolActivitiesQuery.hasNextPage?.value
);
const poolActivitiesIsFetchingNextPage = computed(
  () => poolActivitiesQuery.isFetchingNextPage?.value
);

/**
 * METHODS
 */
function loadMorePoolActivities() {
  poolActivitiesQuery.fetchNextPage.value();
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(Table, {
    tokens: __props.pool ? __props.pool.tokensList : [],
    poolActivities: _unref(poolActivities),
    isLoading: __props.loading || _unref(isLoadingPoolActivities),
    isLoadingMore: _unref(poolActivitiesIsFetchingNextPage),
    isPaginated: _unref(poolActivitiesHasNextPage),
    noResultsLabel: 
      __props.poolActivityType === _unref(PoolTransactionsTab).ALL_ACTIVITY
        ? _ctx.$t('poolTransactions.noResults.allInvestments')
        : _ctx.$t('poolTransactions.noResults.myInvestments')
    ,
    onLoadMore: loadMorePoolActivities
  }, null, 8, ["tokens", "poolActivities", "isLoading", "isLoadingMore", "isPaginated", "noResultsLabel"]))
}
}

})