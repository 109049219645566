import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { flatten } from 'lodash';
import { computed } from 'vue';
import { useRoute } from 'vue-router';

import usePoolSwapsQuery from '@/composables/queries/usePoolSwapsQuery';
import { Pool } from '@/services/pool/types';

import Table from './Table.vue';

/**
 * TYPES
 */
type Props = {
  pool: Pool;
  loading: boolean;
};

/**
 * PROPS
 */

export default _defineComponent({
  props: {
    pool: { type: null, required: true },
    loading: { type: Boolean, required: true, default: false }
  } as unknown as undefined,
  setup(__props: {
  pool: Pool;
  loading: boolean;
}) {



/**
 * COMPOSABLES
 */
const route = useRoute();

/**
 * STATE
 */
const id = route.params.id as string;

/**
 * QUERIES
 */
const poolSwapsQuery = usePoolSwapsQuery(id);

/**
 * COMPUTED
 */
const poolSwaps = computed(() =>
  poolSwapsQuery.data.value
    ? flatten(poolSwapsQuery.data.value.pages.map(page => page.poolSwaps))
    : []
);
const isLoadingPoolSwaps = computed(() => poolSwapsQuery.isLoading.value);
const poolSwapsHasNextPage = computed(() => poolSwapsQuery.hasNextPage?.value);
const poolSwapsIsFetchingNextPage = computed(
  () => poolSwapsQuery.isFetchingNextPage?.value
);

/**
 * METHODS
 */
function loadMorePoolSwaps() {
  poolSwapsQuery.fetchNextPage.value();
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(Table, {
      tokens: __props.pool ? __props.pool.tokensList : [],
      poolSwaps: _unref(poolSwaps),
      isLoading: __props.loading || _unref(isLoadingPoolSwaps),
      isLoadingMore: _unref(poolSwapsIsFetchingNextPage),
      isPaginated: _unref(poolSwapsHasNextPage),
      noResultsLabel: _ctx.$t('poolTransactions.noResults.swaps'),
      onLoadMore: loadMorePoolSwaps
    }, null, 8, ["tokens", "poolSwaps", "isLoading", "isLoadingMore", "isPaginated", "noResultsLabel"])
  ]))
}
}

})