import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, renderList as _renderList, withCtx as _withCtx, createTextVNode as _createTextVNode, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-3d3f459c")
const _hoisted_1 = { class: "py-2 px-6" }
const _hoisted_2 = { class: "flex items-center" }
const _hoisted_3 = { class: "center mr-3 flex" }
const _hoisted_4 = { class: "-mt-1 flex flex-wrap items-center py-4 px-6" }
const _hoisted_5 = { class: "token-item" }
const _hoisted_6 = { class: "font-numeric" }
const _hoisted_7 = { class: "token-item" }
const _hoisted_8 = { class: "font-numeric" }
const _hoisted_9 = {
  key: 0,
  class: "m-1 flex items-center rounded-lg bg-gray-50 p-1 px-2 dark:bg-gray-700"
}
const _hoisted_10 = { class: "font-numeric" }
const _hoisted_11 = { class: "font-numeric flex justify-end py-4 px-6" }
const _hoisted_12 = { class: "py-4 px-6" }
const _hoisted_13 = { class: "wrap flex items-center justify-end whitespace-nowrap text-right" }
_popScopeId()

import { formatDistanceToNow } from 'date-fns';
import { groupBy } from 'lodash';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import { ColumnDefinition } from '@/components/_global/BalTable/BalTable.vue';
import useBreakpoints from '@/composables/useBreakpoints';
import useNumbers, { FNumFormats } from '@/composables/useNumbers';
import useTokens from '@/composables/useTokens';
import { bnum } from '@/lib/utils';
import { Pool, PoolSwap } from '@/services/pool/types';
import useWeb3 from '@/services/web3/useWeb3';

/**
 * TYPES
 */
type TokenAmount = {
  address: string;
  amount: string;
};

type SwapType = 'invest' | 'withdraw' | 'trade';

type SwapRow = {
  label: string;
  timestamp: number;
  formattedDate: string;
  value: number;
  formattedValue: string;
  type: SwapType;
  tx: string;
  tokenAmounts: TokenAmount[];
};

type Props = {
  tokens: string[];
  poolSwaps: PoolSwap[];
  isLoading?: boolean;
  isLoadingMore?: boolean;
  // eslint-disable-next-line vue/require-default-prop -- TODO: Define default prop
  loadMore?: () => void;
  isPaginated?: boolean;
  noResultsLabel?: string;
  pool: Pool;
};

/**
 * PROPS & EMITS
 */

export default _defineComponent({
  props: {
    tokens: { type: Array, required: true },
    poolSwaps: { type: Array, required: true },
    isLoading: { type: Boolean, required: false, default: false },
    isLoadingMore: { type: Boolean, required: false, default: false },
    loadMore: { type: Function, required: false },
    isPaginated: { type: Boolean, required: false, default: false },
    noResultsLabel: { type: String, required: false, default: '' },
    pool: { type: null, required: true }
  } as unknown as undefined,
  emits: ['loadMore'],
  setup(__props: {
  tokens: string[];
  poolSwaps: PoolSwap[];
  isLoading?: boolean;
  isLoadingMore?: boolean;
  // eslint-disable-next-line vue/require-default-prop -- TODO: Define default prop
  loadMore?: () => void;
  isPaginated?: boolean;
  noResultsLabel?: string;
  pool: Pool;
}, { emit }) {

const props = __props




/**
 * COMPOSABLES
 */
const { fNum2 } = useNumbers();
const { t } = useI18n();
const { priceFor } = useTokens();
const { upToLargeBreakpoint } = useBreakpoints();
const { explorerLinks } = useWeb3();

/**
 * COMPUTED
 */
const columns = computed<ColumnDefinition<SwapRow>[]>(() => [
  {
    name: t('action'),
    id: 'action',
    accessor: 'tx',
    Cell: 'actionCell',
    width: 150,
    sortable: false,
  },
  {
    name: t('details'),
    id: 'details',
    accessor: '',
    Cell: 'detailsCell',
    width: 325,
    sortable: false,
  },
  {
    name: t('value'),
    id: 'value',
    accessor: 'value',
    Cell: 'valueCell',
    align: 'right',
    className: 'align-center w-40',
    sortKey: pool => pool.value,
    width: 125,
  },
  {
    name: t('time'),
    id: 'timeAgo',
    accessor: 'timestamp',
    Cell: 'timeCell',
    align: 'right',
    sortKey: pool => pool.timestamp,
    width: 200,
  },
]);

const swapRows = computed<SwapRow[]>(() => {
  if (props.isLoading) {
    return [];
  }

  const groupedSwaps = Object.entries(groupBy(props.poolSwaps, 'tx'));

  return groupedSwaps.map(([tx, swaps]) => {
    const { tokenIn, tokenOut, timestamp } = swaps[0];

    let type: SwapType;
    let label: string;

    if (tokenOut === props.pool.address) {
      type = 'invest';
      label = t('invest');
    } else if (tokenIn === props.pool.address) {
      type = 'withdraw';
      label = t('withdraw.label');
    } else {
      type = 'trade';
      label = t('trade');
    }

    const tokenAmounts = getTokenAmounts(swaps, type);
    const value = getTransactionValue(tokenAmounts, type);

    return {
      label,
      type,
      value,
      formattedValue:
        value > 0 ? fNum2(value, { style: 'currency', abbreviate: true }) : '-',
      timestamp,
      formattedDate: t('timeAgo', [formatDistanceToNow(timestamp)]),
      tx,
      tokenAmounts,
    };
  });
});

/**
 * METHODS
 */
function getTransactionValue(tokenAmounts: TokenAmount[], type: SwapType) {
  if (type === 'trade') {
    const mainTokenAddress = getUnderlyingTokenAddress(tokenAmounts[1].address);
    const mainEquivAmount = getMainTokenEquivalentAmount(
      tokenAmounts[1].address,
      tokenAmounts[1].amount
    );
    return bnum(priceFor(mainTokenAddress)).times(mainEquivAmount).toNumber();
  }

  let total = bnum(0);

  for (const { address, amount } of tokenAmounts) {
    const mainTokenAddress = getUnderlyingTokenAddress(address);
    const mainEquivAmount = getMainTokenEquivalentAmount(address, amount);
    const price = priceFor(mainTokenAddress);
    const amountNumber = Math.abs(parseFloat(mainEquivAmount.toString()));

    // If the price is unknown for any of the positive amounts - the value cannot be computed.
    if (amountNumber > 0 && price === 0) {
      return 0;
    }

    total = total.plus(bnum(amountNumber).times(price));
  }

  return total.toNumber();
}

function getTokenAmounts(swaps: PoolSwap[], type: SwapType) {
  const isInvest = type === 'invest';

  if (type === 'trade') {
    const swap = swaps[0];
    const { tokenIn, tokenOut, tokenAmountIn, tokenAmountOut } = swap;

    return [
      {
        address: tokenIn,
        amount: tokenAmountIn,
      },
      {
        address: tokenOut,
        amount: tokenAmountOut,
      },
    ];
  }
  return swaps.map(swap => {
    let address = isInvest ? swap.tokenIn : swap.tokenOut;

    return {
      address,
      amount: isInvest ? swap.tokenAmountIn : swap.tokenAmountOut,
    };
  });
}

function getUnderlyingTokenAddress(address: string) {
  const linearPools = props.pool?.onchain?.linearPools;
  return linearPools != null && linearPools[address] != null
    ? linearPools[address].mainToken.address
    : address;
}

function getMainTokenEquivalentAmount(address: string, amount: string) {
  const linearPools = props.pool?.onchain?.linearPools;
  return linearPools != null && linearPools[address] != null
    ? bnum(amount).times(linearPools[address].priceRate)
    : bnum(amount);
}

return (_ctx: any,_cache: any) => {
  const _component_BalIcon = _resolveComponent("BalIcon")!
  const _component_BalAsset = _resolveComponent("BalAsset")!
  const _component_BalLink = _resolveComponent("BalLink")!
  const _component_BalTable = _resolveComponent("BalTable")!
  const _component_BalCard = _resolveComponent("BalCard")!

  return (_openBlock(), _createBlock(_component_BalCard, {
    class: "overflow-x-auto",
    square: _unref(upToLargeBreakpoint),
    noBorder: _unref(upToLargeBreakpoint),
    noPad: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_BalTable, {
        columns: _unref(columns),
        data: _unref(swapRows),
        isLoading: __props.isLoading,
        isLoadingMore: __props.isLoadingMore,
        isPaginated: __props.isPaginated,
        skeletonClass: "h-64",
        sticky: "both",
        noResultsLabel: __props.noResultsLabel,
        initialState: {
        sortColumn: 'timeAgo',
        sortDirection: 'desc',
      },
        onLoadMore: _cache[0] || (_cache[0] = ($event: any) => (emit('loadMore')))
      }, {
        actionCell: _withCtx((action) => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                (action.type === 'invest')
                  ? (_openBlock(), _createBlock(_component_BalIcon, {
                      key: 0,
                      name: "plus",
                      size: "sm",
                      class: "text-green-500 dark:text-green-400"
                    }))
                  : (action.type === 'withdraw')
                    ? (_openBlock(), _createBlock(_component_BalIcon, {
                        key: 1,
                        name: "minus",
                        size: "sm",
                        class: "text-red-500"
                      }))
                    : (_openBlock(), _createBlock(_component_BalIcon, {
                        key: 2,
                        name: "repeat",
                        size: "sm",
                        class: "text-green-500 dark:text-green-400"
                      }))
              ]),
              _createElementVNode("div", null, _toDisplayString(action.label), 1)
            ])
          ])
        ]),
        detailsCell: _withCtx((action) => [
          _createElementVNode("div", _hoisted_4, [
            (action.type === 'trade')
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createElementVNode("div", _hoisted_5, [
                    _createVNode(_component_BalAsset, {
                      address: action.tokenAmounts[0].address,
                      class: "mr-2 flex-shrink-0"
                    }, null, 8, ["address"]),
                    _createElementVNode("span", _hoisted_6, _toDisplayString(_unref(fNum2)(action.tokenAmounts[0].amount, _unref(FNumFormats).token)), 1)
                  ]),
                  _createVNode(_component_BalIcon, {
                    name: "arrow-right",
                    class: "mx-1"
                  }),
                  _createElementVNode("div", _hoisted_7, [
                    _createVNode(_component_BalAsset, {
                      address: action.tokenAmounts[1].address,
                      class: "mr-2 flex-shrink-0"
                    }, null, 8, ["address"]),
                    _createElementVNode("span", _hoisted_8, _toDisplayString(_unref(fNum2)(action.tokenAmounts[1].amount, _unref(FNumFormats).token)), 1)
                  ])
                ], 64))
              : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(action.tokenAmounts, (tokenAmount, i) => {
                  return (_openBlock(), _createElementBlock(_Fragment, { key: i }, [
                    (tokenAmount.amount !== '0')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                          _createVNode(_component_BalAsset, {
                            address: tokenAmount.address,
                            class: "mr-2 flex-shrink-0"
                          }, null, 8, ["address"]),
                          _createElementVNode("span", _hoisted_10, _toDisplayString(_unref(fNum2)(tokenAmount.amount, _unref(FNumFormats).token)), 1)
                        ]))
                      : _createCommentVNode("", true)
                  ], 64))
                }), 128))
          ])
        ]),
        valueCell: _withCtx((action) => [
          _createElementVNode("div", _hoisted_11, _toDisplayString(action.formattedValue), 1)
        ]),
        timeCell: _withCtx((action) => [
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("div", _hoisted_13, [
              _createTextVNode(_toDisplayString(action.formattedDate) + " ", 1),
              _createVNode(_component_BalLink, {
                href: _unref(explorerLinks).txLink(action.tx),
                external: "",
                class: "ml-2 flex items-center"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_BalIcon, {
                    name: "arrow-up-right",
                    size: "sm",
                    class: "text-secondary transition-colors hover:text-blue-600 dark:hover:text-blue-400"
                  })
                ]),
                _: 2
              }, 1032, ["href"])
            ])
          ])
        ]),
        _: 1
      }, 8, ["columns", "data", "isLoading", "isLoadingMore", "isPaginated", "noResultsLabel"])
    ]),
    _: 1
  }, 8, ["square", "noBorder"]))
}
}

})