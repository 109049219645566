import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-72326796")
const _hoisted_1 = { class: "flex w-full" }
const _hoisted_2 = {
  key: 1,
  class: "mb-20"
}
_popScopeId()

import { computed, ref, toRef } from 'vue';
import { useI18n } from 'vue-i18n';

import { usePool } from '@/composables/usePool';
import { Pool } from '@/services/pool/types';

import BoostedActivities from '../BoostedPoolActivities/Activities.vue';
import Activities from '../PoolActivities/Activities.vue';
import { PoolTransactionsTab } from '../types';
import TradeTransactions from '../TradeTransactions/TradeTransactions.vue';
/**
 * TYPES
 */
type Props = {
  pool: Pool;
  loading: boolean;
};

/**
 * PROPS
 */

export default _defineComponent({
  props: {
    pool: { type: null, required: true },
    loading: { type: Boolean, required: true, default: false }
  } as unknown as undefined,
  setup(__props: {
  pool: Pool;
  loading: boolean;
}) {

const props = __props


/**
 * COMPUTED
 */
const tabs = computed(() =>
  isStablePhantomPool.value
    ? [
        {
          value: PoolTransactionsTab.ALL_ACTIVITY,
          label: t('poolTransactions.tabs.allTransactions'),
        },
        {
          value: PoolTransactionsTab.USER_ACTIVITY,
          label: t('poolTransactions.tabs.myTransactions'),
        },
      ]
    : [
        {
          value: PoolTransactionsTab.ALL_ACTIVITY,
          label: t('poolTransactions.tabs.allInvestments'),
        },
        {
          value: PoolTransactionsTab.USER_ACTIVITY,
          label: t('poolTransactions.tabs.myInvestments'),
        },
        {
          value: PoolTransactionsTab.TRADES,
          label: t('poolTransactions.tabs.trades'),
        },
      ]
);

/**
 * COMPOSABLES
 */
const { isStablePhantomPool } = usePool(toRef(props, 'pool'));
const { t } = useI18n();

/**
 * STATE
 */
const activeTab = ref(tabs.value[0].value);

return (_ctx: any,_cache: any) => {
  const _component_BalTabsSwitch = _resolveComponent("BalTabsSwitch")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_BalTabsSwitch, {
          modelValue: activeTab.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (activeTab.value = $event)),
          tabs: _unref(tabs),
          noPad: "",
          class: "flex-1"
        }, null, 8, ["modelValue", "tabs"])
      ])
    ]),
    (_unref(isStablePhantomPool))
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (activeTab.value === _unref(PoolTransactionsTab).ALL_ACTIVITY)
            ? (_openBlock(), _createBlock(BoostedActivities, {
                key: 0,
                poolActivityType: _unref(PoolTransactionsTab).ALL_ACTIVITY,
                pool: __props.pool,
                loading: __props.loading
              }, null, 8, ["poolActivityType", "pool", "loading"]))
            : (activeTab.value === _unref(PoolTransactionsTab).USER_ACTIVITY)
              ? (_openBlock(), _createBlock(BoostedActivities, {
                  key: 1,
                  poolActivityType: _unref(PoolTransactionsTab).USER_ACTIVITY,
                  pool: __props.pool,
                  loading: __props.loading
                }, null, 8, ["poolActivityType", "pool", "loading"]))
              : _createCommentVNode("", true)
        ], 64))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (activeTab.value === _unref(PoolTransactionsTab).ALL_ACTIVITY)
            ? (_openBlock(), _createBlock(Activities, {
                key: 0,
                poolActivityType: _unref(PoolTransactionsTab).ALL_ACTIVITY,
                pool: __props.pool,
                loading: __props.loading
              }, null, 8, ["poolActivityType", "pool", "loading"]))
            : (activeTab.value === _unref(PoolTransactionsTab).USER_ACTIVITY)
              ? (_openBlock(), _createBlock(Activities, {
                  key: 1,
                  poolActivityType: _unref(PoolTransactionsTab).USER_ACTIVITY,
                  pool: __props.pool,
                  loading: __props.loading
                }, null, 8, ["poolActivityType", "pool", "loading"]))
              : (activeTab.value === _unref(PoolTransactionsTab).TRADES)
                ? (_openBlock(), _createBlock(TradeTransactions, {
                    key: 2,
                    pool: __props.pool,
                    loading: __props.loading
                  }, null, 8, ["pool", "loading"]))
                : _createCommentVNode("", true)
        ]))
  ]))
}
}

})