import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-37e8c239")
const _hoisted_1 = { class: "relative" }
const _hoisted_2 = { class: "w-full rounded-xl p-4 transition-colors hover:bg-gray-50 dark:hover:bg-gray-800" }
const _hoisted_3 = { class: "relative bg-white dark:bg-gray-850" }
_popScopeId()

import { getAddress } from 'ethers/lib/utils';
import { computed, ref } from 'vue';

import BalLoadingBlock from '@/components/_global/BalLoadingBlock/BalLoadingBlock.vue';
import AnimatePresence from '@/components/animate/AnimatePresence.vue';
import useStaking from '@/composables/staking/useStaking';
import useNumbers, { FNumFormats } from '@/composables/useNumbers';
import useTokens from '@/composables/useTokens';
import { bnum } from '@/lib/utils';
import { Pool } from '@/services/pool/types';

import StakePreviewModal from '../../../stake/StakePreviewModal.vue';

type Props = {
  pool: Pool;
};

export default _defineComponent({
  props: {
    pool: { type: null, required: true }
  } as unknown as undefined,
  setup(__props: {
  pool: Pool;
}) {

const props = __props


/**
 * STATE
 */

const isStakePreviewVisible = ref(false);
const stakeAction = ref('');

/**
 * COMPOSABLES
 */
const { fNum2 } = useNumbers();
const { balanceFor } = useTokens();
const {
  userData: {
    refetchStakedShares,
    isStakedSharesIdle,
    isLoadingStakedShares,
    isRefetchingStakedShares,
    stakedSharesForProvidedPool,
    isLoadingBoosts,
  },
  isPoolEligibleForStaking,
  isLoadingPoolEligibility,
} = useStaking();

/**
 * COMPUTED
 */
const fiatValueOfStakedShares = computed(() => {
  return bnum(props.pool.totalLiquidity)
    .div(props.pool.totalShares)
    .times((stakedSharesForProvidedPool.value || 0).toString())
    .toString();
});

const fiatValueOfUnstakedShares = computed(() => {
  return bnum(props.pool.totalLiquidity)
    .div(props.pool.totalShares)
    .times(balanceFor(getAddress(props.pool.address)))
    .toString();
});

/**
 * METHODS
 */
function showStakePreview() {
  if (fiatValueOfUnstakedShares.value === '0') return;
  stakeAction.value = 'stake';
  isStakePreviewVisible.value = true;
}

function showUnstakePreview() {
  if (fiatValueOfStakedShares.value === '0') return;
  stakeAction.value = 'unstake';
  isStakePreviewVisible.value = true;
}

function handlePreviewClose() {
  isStakePreviewVisible.value = false;
}

async function handleActionSuccess() {
  await refetchStakedShares.value();
}

return (_ctx: any,_cache: any) => {
  const _component_BalIcon = _resolveComponent("BalIcon")!
  const _component_BalStack = _resolveComponent("BalStack")!
  const _component_BalTooltip = _resolveComponent("BalTooltip")!
  const _component_BalBtn = _resolveComponent("BalBtn")!
  const _component_BalAccordion = _resolveComponent("BalAccordion")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(AnimatePresence, {
      isVisible: 
        !_unref(isLoadingStakedShares) &&
        !_unref(isStakedSharesIdle) &&
        !_unref(isLoadingPoolEligibility) &&
        !_unref(isLoadingBoosts)
      
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_BalAccordion, {
            class: _normalizeClass(['shadow-2xl', { handle: _unref(isPoolEligibleForStaking) }]),
            sections: [
            {
              title: _ctx.$t('staking.stakingIncentives'),
              id: 'staking-incentives',
              handle: 'staking-handle',
              isDisabled: !_unref(isPoolEligibleForStaking),
            },
          ]
          }, {
            "staking-handle": _withCtx(() => [
              _createElementVNode("button", _hoisted_2, [
                _createVNode(_component_BalStack, {
                  horizontal: "",
                  justify: "between",
                  align: "center"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_BalStack, {
                      spacing: "sm",
                      align: "center"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", {
                          class: _normalizeClass([
                      'flex items-center rounded-full p-1 text-white',
                      {
                        'bg-green-500': _unref(isPoolEligibleForStaking),
                        'bg-gray-400': !_unref(isPoolEligibleForStaking),
                      },
                    ])
                        }, [
                          (_unref(isPoolEligibleForStaking))
                            ? (_openBlock(), _createBlock(_component_BalIcon, {
                                key: 0,
                                size: "sm",
                                name: "check"
                              }))
                            : (_openBlock(), _createBlock(_component_BalIcon, {
                                key: 1,
                                size: "sm",
                                name: "x"
                              }))
                        ], 2),
                        _createElementVNode("h6", null, _toDisplayString(_ctx.$t('staking.stakingIncentives')), 1)
                      ]),
                      _: 1
                    }),
                    (_unref(isPoolEligibleForStaking))
                      ? (_openBlock(), _createBlock(_component_BalStack, {
                          key: 0,
                          horizontal: "",
                          spacing: "sm",
                          align: "center"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_BalIcon, {
                              name: "chevron-down",
                              class: "text-blue-500"
                            })
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                })
              ])
            ]),
            "staking-incentives": _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_BalStack, {
                  vertical: "",
                  spacing: "sm",
                  class: "border-t py-4 px-4 dark:border-gray-900"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_BalStack, {
                      horizontal: "",
                      justify: "between"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("span", null, _toDisplayString(_ctx.$t('staked')) + " " + _toDisplayString(_ctx.$t('lpTokens')), 1),
                        _createVNode(_component_BalStack, {
                          horizontal: "",
                          spacing: "sm",
                          align: "center"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(AnimatePresence, { isVisible: _unref(isRefetchingStakedShares) }, {
                              default: _withCtx(() => [
                                _createVNode(BalLoadingBlock, { class: "h-5" })
                              ]),
                              _: 1
                            }, 8, ["isVisible"]),
                            _createVNode(AnimatePresence, {
                              isVisible: !_unref(isRefetchingStakedShares)
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("span", null, _toDisplayString(_unref(fNum2)(_unref(fiatValueOfStakedShares), _unref(FNumFormats).fiat)), 1)
                              ]),
                              _: 1
                            }, 8, ["isVisible"]),
                            _createVNode(_component_BalTooltip, {
                              text: _ctx.$t('staking.stakedLpTokensTooltip')
                            }, null, 8, ["text"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_BalStack, {
                      horizontal: "",
                      justify: "between"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("span", null, _toDisplayString(_ctx.$t('unstaked')) + " " + _toDisplayString(_ctx.$t('lpTokens')), 1),
                        _createVNode(_component_BalStack, {
                          horizontal: "",
                          spacing: "sm",
                          align: "center"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(AnimatePresence, { isVisible: _unref(isRefetchingStakedShares) }, {
                              default: _withCtx(() => [
                                _createVNode(BalLoadingBlock, { class: "h-5" })
                              ]),
                              _: 1
                            }, 8, ["isVisible"]),
                            _createVNode(AnimatePresence, {
                              isVisible: !_unref(isRefetchingStakedShares)
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("span", null, _toDisplayString(_unref(fNum2)(_unref(fiatValueOfUnstakedShares), _unref(FNumFormats).fiat)), 1)
                              ]),
                              _: 1
                            }, 8, ["isVisible"]),
                            _createVNode(_component_BalTooltip, {
                              text: _ctx.$t('staking.unstakedLpTokensTooltip')
                            }, null, 8, ["text"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_BalStack, {
                      horizontal: "",
                      spacing: "sm",
                      class: "mt-2"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_BalBtn, {
                          color: "gradient",
                          size: "sm",
                          disabled: _unref(fiatValueOfUnstakedShares) === '0',
                          onClick: showStakePreview
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('stake')), 1)
                          ]),
                          _: 1
                        }, 8, ["disabled"]),
                        _createVNode(_component_BalBtn, {
                          outline: "",
                          color: "blue",
                          size: "sm",
                          disabled: _unref(fiatValueOfStakedShares) === '0',
                          onClick: showUnstakePreview
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('unstake')), 1)
                          ]),
                          _: 1
                        }, 8, ["disabled"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ])
            ]),
            _: 1
          }, 8, ["class", "sections"])
        ])
      ]),
      _: 1
    }, 8, ["isVisible"]),
    _createVNode(AnimatePresence, {
      isVisible: 
        _unref(isLoadingStakedShares) || _unref(isLoadingPoolEligibility) || _unref(isLoadingBoosts)
      ,
      unmountInstantly: ""
    }, {
      default: _withCtx(() => [
        _createVNode(BalLoadingBlock, { class: "h-12" })
      ]),
      _: 1
    }, 8, ["isVisible"]),
    _createVNode(StakePreviewModal, {
      isVisible: isStakePreviewVisible.value,
      pool: __props.pool,
      action: stakeAction.value,
      onClose: handlePreviewClose,
      onSuccess: handleActionSuccess
    }, null, 8, ["isVisible", "pool", "action"])
  ]))
}
}

})