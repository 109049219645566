import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-07916a2e")
const _hoisted_1 = ["textContent"]
_popScopeId()

import { toRef } from 'vue';

import { usePool } from '@/composables/usePool';
import { Pool } from '@/services/pool/types';
import useBreakpoints from '@/composables/useBreakpoints';
import InvestmentTransactions from './InvestmentTransactions/InvestmentTransactions.vue';

/**
 * TYPES
 */
type Props = {
  pool: Pool;
  loading: boolean;
};

/**
 * PROPS
 */

export default _defineComponent({
  props: {
    pool: { type: null, required: true },
    loading: { type: Boolean, required: true, default: false }
  } as unknown as undefined,
  setup(__props: {
  pool: Pool;
  loading: boolean;
}) {

const props = __props


/**
 * COMPOSABLES
 */
const { isStablePhantomPool } = usePool(toRef(props, 'pool'));

const { isMobile, isDesktop } = useBreakpoints();


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("h4", {
      class: " mb-[24px]",
      textContent: _toDisplayString(_ctx.$t('poolTransactions.tabs.investmentsTrades'))
    }, null, 8, _hoisted_1),
    _createVNode(InvestmentTransactions, {
      pool: __props.pool,
      loading: __props.loading
    }, null, 8, ["pool", "loading"])
  ], 64))
}
}

})