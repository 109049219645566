import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full border-t border-gray-200 bg-gray-50 p-4 dark:border-gray-900 dark:bg-gray-800" }
const _hoisted_2 = { class: "text-secondary text-sm" }
const _hoisted_3 = { class: "mb-4 flex items-center justify-between" }
const _hoisted_4 = {
  key: 1,
  class: "grid grid-cols-2 gap-2"
}

import { computed, toRef } from 'vue';

import useWithdrawMath from '@/components/forms/pool_actions/WithdrawForm/composables/useWithdrawMath';
import useNumbers, { FNumFormats } from '@/composables/useNumbers';
import { lpTokensFor } from '@/composables/usePool';
import useTokens from '@/composables/useTokens';
import { bnum, isSameAddress } from '@/lib/utils';
import { Pool } from '@/services/pool/types';
import useWeb3 from '@/services/web3/useWeb3';

/**
 * TYPES
 */
type Props = {
  pool: Pool;
  missingPrices: boolean;
};

/**
 * PROPS
 */

export default _defineComponent({
  props: {
    pool: { type: null, required: true },
    missingPrices: { type: Boolean, required: true }
  } as unknown as undefined,
  setup(__props: {
  pool: Pool;
  missingPrices: boolean;
}) {

const props = __props


/**
 * COMPOSABLES
 */
const { hasBpt } = useWithdrawMath(toRef(props, 'pool'));
const { balanceFor, nativeAsset, wrappedNativeAsset } = useTokens();
const { fNum2, toFiat } = useNumbers();
const { isWalletReady, startConnectWithInjectedProvider } = useWeb3();

/**
 * COMPUTED
 */
const fiatTotal = computed(() => {
  const fiatValue = lpTokensFor(props.pool)
    .map(address => {
      let tokenBalance = '0';

      if (isSameAddress(address, wrappedNativeAsset.value.address)) {
        const wrappedBalance = balanceFor(address);
        const nativeBalance = balanceFor(nativeAsset.address);
        tokenBalance = bnum(nativeBalance).gt(wrappedBalance)
          ? nativeBalance
          : wrappedBalance;
      } else {
        tokenBalance = balanceFor(address);
      }

      return toFiat(tokenBalance, address);
    })
    .reduce((total, value) => bnum(total).plus(value).toString());

  return fNum2(fiatValue, FNumFormats.fiat);
});

return (_ctx: any,_cache: any) => {
  const _component_BalBtn = _resolveComponent("BalBtn")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('basedOnTokensInWallet')), 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("h5", null, _toDisplayString(_ctx.$t('youCanInvest')), 1),
      _createElementVNode("h5", null, _toDisplayString(_unref(isWalletReady) ? _unref(fiatTotal) : '-'), 1)
    ]),
    (!_unref(isWalletReady))
      ? (_openBlock(), _createBlock(_component_BalBtn, {
          key: 0,
          label: _ctx.$t('connectWallet'),
          color: "gradient",
          block: "",
          onClick: _unref(startConnectWithInjectedProvider)
        }, null, 8, ["label", "onClick"]))
      : (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_BalBtn, {
            tag: "router-link",
            to: { name: 'invest' },
            label: _ctx.$t('invest'),
            color: "gradient",
            block: ""
          }, null, 8, ["label"]),
          _createVNode(_component_BalBtn, {
            tag: _unref(hasBpt) ? 'router-link' : 'div',
            to: { name: 'withdraw' },
            label: _ctx.$t('withdraw.label'),
            disabled: !_unref(hasBpt),
            color: "blue",
            outline: "",
            block: ""
          }, null, 8, ["tag", "label", "disabled"])
        ]))
  ]))
}
}

})